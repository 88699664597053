<script>
    // import TextEditor from "../../../utils/text-editor"
    import SlEditor from "@/components/layout/components/Editor"
    // import {UTooltip} from "uloc-vue";
    import FullScreenMixin from 'uloc-vue/src/mixins/fullscreen'
    import FullScreenEsc from '../../../../../mixins/FullScreenEsc'
    import CommentOptionsMenu from "./context-comments-options"

    // import MockComments from '../../../assets/_mock/tarefas-comentarios'
    // import TaskComment from "../comments/comment"

    import TaskMixin from './mixin'
    import {createComment, showCommentLogByReferId, historico} from "@/domain/tarefas/services"
    import SlLoading from "@/components/layout/components/Loading"
    import HistoryState from "@/domain/tarefas/helpers/HistoryState"
    import TaskHistory from "@/components/sltarefas/components/tarefa/task-history"

    export default {
      name: 'task-comments-tab',
      inject: ['containerTab', 'container'],
      provide: function () {
        return {
          appContainer: this
        }
      },
      mixins: [FullScreenMixin, FullScreenEsc, TaskMixin],
      data () {
        return {
          active: 'tudo',
          historico: [],
          comment: '',
          sending: false,
          loading: false,
          counts: null
        }
      },
      computed: {
        /*filteredComments () {
          let data = this.comments
          let sortKey = 'date'
          let order = -1

          // Force order by id, if date equals
          data = data.slice().sort(function (a, b) {
            a = a['id']
            b = b['id']
            return (a === b ? 0 : a > b ? 1 : -1) * -1
          })

          if (sortKey) {
            data = data.slice().sort(function (a, b) {
              a = a[sortKey]
              b = b[sortKey]
              return (a === b ? 0 : a > b ? 1 : -1) * order
            })
          }
          return data
        },*/
        filteredHistory () {
          return this.historico
        },
        classes () {
          let css = []

          this.inFullscreen && css.push('fullscreen')
          this.sending && css.push('sending')

          return css
        },
      },
      watch: {
        active (v) {
          this.load(v)
        }
      },
      mounted () {
        this.containerTab.$on('updateHistory', this.loadActive)
        this.container && this.container.$on('update', () => {
          this.load(this.active)
        })
        this.load(this.active)
        HistoryState.listen = this.loadHistoryByRefer
        /*this.comments.shift({
          id: 0,
          type: 'system',
          user: this.task.author,
          action: {
            code: 1,
            description: 'Criou esta tarefa'
          },
          reply: null,
          date: '2018-11-28 10:27:52',
          message: 'Não podemos atrasar esta atividade :)'
        })*/
      },
      beforeDestroy() {
        this.containerTab.$off('updateHistory', this.loadActive)
        HistoryState.listen = null
      },
      destroyed () {
      },
      methods: {
        changeTab(m) {
          if (!this.hasData(m) && m.name !== 'tudo') return
          this.active = m.name
        },
        loadActive () {
          this.load(this.active)
        },
        load(t) {
          this.loading = true
          this.historico = null
          historico(this.task, t)
              .then(({data}) => {
                console.log(data)
                this.historico = data.historico
                this.counts = data.counts
                this.loading = false
              })
              .catch(error => {
                this.loading = false
                this.alertApiError(error)
              })
        },
        send () {
          if (this.sending) return
          console.log('Send')
          if (String(this.$refs.editor.contentNode().textContent).trim().length === 0) {
            this.$uloc.notify({
              color: 'black',
              message: `Digite algo para seu comentário.`
            })
            return
          }
          const comment = this.$refs.editor.htmlContent()
          const marks = []
          const marksNodes = this.$refs.editor.contentNode().querySelectorAll('[data-person-mark-id]')
          if (marksNodes) {
            for (let i = 0; i < marksNodes.length; i++) {
              marks.push(marksNodes[i].dataset.personMarkId)
            }
          }
          this.sending = true
          createComment(this.task, {
            text: comment,
            marks: marks
          })
            .then(response => {
              // HistoryState.listen && HistoryState.listen(response.data.id)
              this.comment = ''
              this.sending = false
              this.load(this.active)
            })
            .catch(error => {
              this.sending = false
              this.alertApiError(error)
            })
        },
        loadHistoryByRefer(referId) {
          console.log('Loading history by refer id ', referId)
          showCommentLogByReferId(this.task, referId)
              .then(({data}) => {
                this.historico.unshift(data)
              })
              .catch(error => {
                this.alertApiError(error)
              })
        },
        hasData (m) {
          return this.counts && typeof this.counts[m.name] !== 'undefined' && Number(this.counts[m.name]) > 0 ? Number(this.counts[m.name]) : null
        }
      },
      components: {
        TaskHistory,
        SlLoading,
        // TaskComment,
        CommentOptionsMenu,
        // TextEditor,
        SlEditor
      }
    }
</script>

<template>
    <div class="task-comments" :class="classes">
        <div class="new-comment">
            <div class="new-comment-content">
                <div class="comment-pre-input">
                    <a @click="toggleFullscreen()" class="btn-fullscreen">
                        <u-icon :name="inFullscreen ? 'minus-square' : 'expand'" type="fa"></u-icon>
                        <u-tooltip :offset="[4,4]">
                            <div v-if="!inFullscreen">Ver comentários na tela inteira</div>
                            <div v-else>Voltar para o tamanho normal</div>
                        </u-tooltip>
                    </a>
                </div>
                <div class="comment-input">
                  <sl-editor
                      ref="editor"
                      class="task-comment-editor no-fullscreen"
                      v-model="comment"
                      placeholder="Use @ para mencionar pessoas e # para tarefas. Para anexar arquivos, arraste-os aqui."
                      :show-toolbar-on-focus="true"
                      :close-click-outside="true"
                      @focus="() => { textEditing = true }"
                      :toolbar-menu="{fullscreen: false}"
                  />
                </div>
                <div class="comment-buttons">
                    <a @click="send" v-shortkey="['ctrl', 'enter']" @shortkey="send" class="comment-btn-send">
                        <u-icon v-if="!sending" name="send"></u-icon>
                        <sl-loading content="" class="size-18" v-else />
                        <u-tooltip v-if="!sending" :offset="[10,10]">Enviar <span>Ctrl + Enter</span></u-tooltip>
                    </a>
                    <a @click="containerTab.click('attachments')" class="comment-btn-attachment">
                        <u-icon name="attach_file">
                            <u-tooltip :offset="[10,10]">Anexar arquivo</u-tooltip>
                        </u-icon>
                    </a>
                    <a class="comment-btn-options">
                        <u-icon name="ellipsis-v" type="fa"></u-icon>
                        <u-tooltip :offset="[10,10]">Opções</u-tooltip>
                        <comment-options-menu :task="task" />
                    </a>
                </div>
            </div>
        </div>

        <div class="comments">
            <div class="comments-scroll">
                <div :key="h.id" v-for="h in filteredHistory">
<!--                    <task-comment :comment="comment"/>-->
                  <task-history :h="h" />
                </div>
            </div>
        </div>

    </div>
</template>
